/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IWebform {
  '#type': string;
  '#webform': string;
  '#webform_id': string;
  '#webform_key': string;
  '#webform_parent_key': string;
  '#webform_parent_flexbox': boolean;
  '#webform_depth': number;
  '#webform_children': WebformChildren;
  '#webform_multiple': boolean;
  '#webform_composite': boolean;
  '#multiple'?: boolean;
  '#webform_parents': string[];
  '#title': null | string;
  '#admin_title': null | string;
  '#webform_plugin_id': string;
  '#required'?: boolean;
  '#options'?: Options;
  '#description'?: string;
  '#default_value'?: string;
  '#title_display'?: string;
  '#placeholder'?: string;
  '#hidden'?: boolean;
  '#readonly'?: boolean;
  '#react_key'?: string;
  '#states'?: {
    visible: {
      [key: string]: {
        value: string;
      };
    };
  };
  '#weight'?: number;
  '#element'?: {
    [key: string]: DynimicVisibleField;
  };
  '#answers'?: { [key: string]: any };
  '#questions'?: { [key: string]: any };
  name?: { [key: string]: any };
  surname?: { [key: string]: any };
  role?: { [key: string]: any };
  phone?: { [key: string]: any };
  email?: { [key: string]: any };
  company?: { [key: string]: any };
  object?: { [key: string]: any };
  message?: { [key: string]: any };
}

export interface DynimicVisibleField {
  '#type': string;
  '#required'?: boolean;
  '#title': string;
  '#break'?: boolean;
  '#webform_key': string;
}

export interface Options {
  first_consultancy: string;
  customs_clearance: string;
  customs_master: string;
}

export interface WebformChildren {
  content: Content;
  headers: Headers;
}

export interface Headers {
  'X-Sub-Content-Type': null;
  'Content-Type'?: ContentType;
}

export interface IWebFormRes {
  [key: string]: IWebform;
}

export enum WebformIdEnum {
  CONSULTANCY_SERVICES = 'consultancy_services',
  ACADEMY_FLA = 'academy_fla',
  ACADEMY_FLA_FUNDING_REQ = 'academy_fla_funding_request',
  ACADEMY_FLA_CONTACT = 'academy_fla',
  ACADEMY_ARCHITECT = 'academy_architect',
  CERTIFICATION_CONTACT = 'certification_contact',
  CUSTOMS_CLEARANCE_CONTACT = 'customs_clearance_contact',
  CUSTOM_CLEARANCE = 'customs_contact',
  ACADEMY_CONTACT = 'academy_contact',
  FINANCE_CONTACT = 'finance_contact',
  INTELLECTUAL_PROPERTY_CONATCT = 'intellectual_property_contact',
  STUDY_CENTER_CONTACT = 'study_center_contact',
  FEEDBACK = 'feedback',
  CIRCULARITY_TOOL_CONTACT = 'circularity_tool_contact',
  CIRCULARITY_TOOL = 'circularity_tool',
  MASTER_DOGANE = 'customs_master',
  EXPERT_DOGANE = 'customs_expert',
  GENERAL_SURVEY = 'survey',
}

export type DynamicFormType =
  | 'text'
  | 'email'
  | 'textarea'
  | 'radios'
  | 'multi-field'
  | 'select'
  | 'multiSelect'
  | 'number'
  | 'checkboxes'
  | 'webform_checkboxes_other'
  | 'webform_radios_other'
  | 'webform_likert';

export interface IDynamicFieldForm {
  type: DynamicFormType;
  options?:
    | {
        label: string;
        value: string;
      }[]
    | undefined;
  description?: string;
  name: string;
  required: boolean;
  label: string | null;
  grid: number;
  children?: IDynamicFieldForm[];
  visibilityParentKey?: string;
  visibilityParentValue?: string;
  visibilityChildrenKey?: string;
  isMultiple?: boolean;
  readOnly?: boolean;
  entity?: string;
  entityKey?: string;
  answers?: {
    value: string;
    label: string;
  }[];
  questions?: {
    value: string;
    label: string;
  }[];
  editable?: boolean;
}

export interface IWebformToolSettingsRes {
  utils_container: CustomSurveyGroup;
  custom_survey_group: CustomSurveyGroup;
  custom_survey_questions_group: CustomSurveyQuestionsGroup;
  questions_group_1: QuestionsGroup1;
  questions_group_2: QuestionsGroup2;
  questions_group_3: QuestionsGroup3;
  questions_group_4: QuestionsGroup4;
  questions_group_5: QuestionsGroup5;
  questions_group_6: QuestionsGroup6;
}

export interface TotalsContainer {
  '#type': Type;
  survey_total_amount: CustomSurveyGroup;
  survey_total_formatted: CustomSurveyGroup;
  survey_total_percentage: CustomSurveyGroup;
  questions_group_1_total_amount: CustomSurveyGroup;
  questions_group_1_total_formatted: CustomSurveyGroup;
  questions_group_1_total_percentage: CustomSurveyGroup;
  questions_group_2_total_amount: CustomSurveyGroup;
  questions_group_2_total_formatted: CustomSurveyGroup;
  questions_group_2_total_percentage: CustomSurveyGroup;
  questions_group_3_total_amount: CustomSurveyGroup;
  questions_group_3_total_formatted: CustomSurveyGroup;
  questions_group_3_total_percentage: CustomSurveyGroup;
  questions_group_4_total_amount: CustomSurveyGroup;
  questions_group_4_total_formatted: CustomSurveyGroup;
  questions_group_4_total_percentage: CustomSurveyGroup;
  questions_group_5_total_amount: CustomSurveyGroup;
  questions_group_5_total_formatted: CustomSurveyGroup;
  questions_group_5_total_percentage: CustomSurveyGroup;
  questions_group_6_total_amount: CustomSurveyGroup;
  questions_group_6_total_formatted: CustomSurveyGroup;
  questions_group_6_total_percentage: CustomSurveyGroup;
  '#webform': Webform;
  '#webform_id': string;
  '#webform_key': string;
  '#webform_parent_key': string;
  '#webform_parent_flexbox': boolean;
  '#webform_depth': number;
  '#webform_children': WebformChildren;
  '#webform_multiple': boolean;
  '#webform_composite': boolean;
  '#webform_parents': string[];
  '#title': null;
  '#admin_title': null;
  '#webform_plugin_id': Type;
}

export interface CustomSurveyGroup {
  '#type': Type;
  custom_survey?: CustomSurveyGroup;
  '#webform': Webform;
  '#webform_id': string;
  '#webform_key': string;
  '#webform_parent_key': string;
  '#webform_parent_flexbox': boolean;
  '#webform_depth': number;
  '#webform_children': WebformChildren;
  '#webform_multiple': boolean;
  '#webform_composite': boolean;
  '#webform_parents': string[];
  '#title': null | string;
  '#admin_title': null | string;
  '#webform_plugin_id': Type;
  '#options'?: Options;
  '#hidden'?: boolean;
  fla_draft?: CustomSurveyGroup;
  totals_container?: TotalsContainer;
  '#default_value'?: boolean;
  '#readonly'?: boolean;
}

export type Type = 'container' | 'radios' | 'checkbox' | 'textfield';

export type Webform = 'circularity_tool';

export interface WebformChildren {
  content: Content;
  headers: Headers;
}

export type Content = '[]';

export type ContentType = 'application/json';

export type XSubContentType = 'text/plain';

export interface Options {
  true?: The100;
  false?: The0;
  '0'?: The0;
  '10'?: The10;
  '25'?: The10;
  '50'?: The50;
  '100'?: The100;
  '75'?: string;
}

export type The0 = '0 %' | 'No';

export type The10 = '>0 - 10 %' | '10 - 25 %';

export type The100 = '50 - 100 %' | 'Si' | '20 - 100 %';

export type The50 = '25 - 50 %';

export interface CustomSurveyQuestionsGroup {
  '#type': Type;
  '#hidden': boolean;
  custom_survey_question_a: CustomSurveyGroup;
  custom_survey_question_b: CustomSurveyGroup;
  custom_survey_question_c: CustomSurveyGroup;
  custom_survey_question_d: CustomSurveyGroup;
  custom_survey_question_e: CustomSurveyGroup;
  custom_survey_question_f: CustomSurveyGroup;
  custom_survey_question_g: CustomSurveyGroup;
  custom_survey_question_h: CustomSurveyGroup;
  '#webform': Webform;
  '#webform_id': string;
  '#webform_key': string;
  '#webform_parent_key': string;
  '#webform_parent_flexbox': boolean;
  '#webform_depth': number;
  '#webform_children': WebformChildren;
  '#webform_multiple': boolean;
  '#webform_composite': boolean;
  '#webform_parents': string[];
  '#title': null;
  '#admin_title': null;
  '#webform_plugin_id': Type;
}

export interface QuestionsGroup1 {
  '#type': Type;
  question_1: CustomSurveyGroup;
  question_2: CustomSurveyGroup;
  question_3: CustomSurveyGroup;
  question_4: CustomSurveyGroup;
  question_5: CustomSurveyGroup;
  question_6: CustomSurveyGroup;
  question_7: CustomSurveyGroup;
  question_8: CustomSurveyGroup;
  question_9: CustomSurveyGroup;
  question_10: CustomSurveyGroup;
  '#webform': Webform;
  '#webform_id': string;
  '#webform_key': string;
  '#webform_parent_key': string;
  '#webform_parent_flexbox': boolean;
  '#webform_depth': number;
  '#webform_children': WebformChildren;
  '#webform_multiple': boolean;
  '#webform_composite': boolean;
  '#webform_parents': string[];
  '#title': null;
  '#admin_title': null;
  '#webform_plugin_id': Type;
}

export interface QuestionsGroup2 {
  '#type': Type;
  question_11: CustomSurveyGroup;
  question_12: CustomSurveyGroup;
  question_13: CustomSurveyGroup;
  question_14: CustomSurveyGroup;
  question_15: CustomSurveyGroup;
  '#webform': Webform;
  '#webform_id': string;
  '#webform_key': string;
  '#webform_parent_key': string;
  '#webform_parent_flexbox': boolean;
  '#webform_depth': number;
  '#webform_children': WebformChildren;
  '#webform_multiple': boolean;
  '#webform_composite': boolean;
  '#webform_parents': string[];
  '#title': null;
  '#admin_title': null;
  '#webform_plugin_id': Type;
}

export interface QuestionsGroup3 {
  '#type': Type;
  question_16: CustomSurveyGroup;
  question_17: CustomSurveyGroup;
  question_18: CustomSurveyGroup;
  question_19: CustomSurveyGroup;
  question_20: CustomSurveyGroup;
  question_21: CustomSurveyGroup;
  '#webform': Webform;
  '#webform_id': string;
  '#webform_key': string;
  '#webform_parent_key': string;
  '#webform_parent_flexbox': boolean;
  '#webform_depth': number;
  '#webform_children': WebformChildren;
  '#webform_multiple': boolean;
  '#webform_composite': boolean;
  '#webform_parents': string[];
  '#title': null;
  '#admin_title': null;
  '#webform_plugin_id': Type;
}

export interface QuestionsGroup4 {
  '#type': Type;
  question_22: CustomSurveyGroup;
  question_23: CustomSurveyGroup;
  question_24: CustomSurveyGroup;
  question_25: CustomSurveyGroup;
  question_26: CustomSurveyGroup;
  question_27: CustomSurveyGroup;
  '#webform': Webform;
  '#webform_id': string;
  '#webform_key': string;
  '#webform_parent_key': string;
  '#webform_parent_flexbox': boolean;
  '#webform_depth': number;
  '#webform_children': WebformChildren;
  '#webform_multiple': boolean;
  '#webform_composite': boolean;
  '#webform_parents': string[];
  '#title': null;
  '#admin_title': null;
  '#webform_plugin_id': Type;
}

export interface QuestionsGroup5 {
  '#type': Type;
  question_28: CustomSurveyGroup;
  question_29: CustomSurveyGroup;
  question_30: CustomSurveyGroup;
  question_31: CustomSurveyGroup;
  question_32: CustomSurveyGroup;
  question_33: CustomSurveyGroup;
  question_34: CustomSurveyGroup;
  question_35: CustomSurveyGroup;
  question_36: CustomSurveyGroup;
  question_37: CustomSurveyGroup;
  question_38: CustomSurveyGroup;
  question_39: CustomSurveyGroup;
  question_40: CustomSurveyGroup;
  question_41: CustomSurveyGroup;
  question_42: CustomSurveyGroup;
  question_43: CustomSurveyGroup;
  question_44: CustomSurveyGroup;
  question_45: CustomSurveyGroup;
  question_46: CustomSurveyGroup;
  question_47: CustomSurveyGroup;
  question_48: CustomSurveyGroup;
  question_49: CustomSurveyGroup;
  question_50: CustomSurveyGroup;
  question_51: CustomSurveyGroup;
  question_52: CustomSurveyGroup;
  question_53: CustomSurveyGroup;
  '#webform': Webform;
  '#webform_id': string;
  '#webform_key': string;
  '#webform_parent_key': string;
  '#webform_parent_flexbox': boolean;
  '#webform_depth': number;
  '#webform_children': WebformChildren;
  '#webform_multiple': boolean;
  '#webform_composite': boolean;
  '#webform_parents': string[];
  '#title': null;
  '#admin_title': null;
  '#webform_plugin_id': Type;
}

export interface QuestionsGroup6 {
  '#type': Type;
  question_54: CustomSurveyGroup;
  question_55: CustomSurveyGroup;
  question_56: CustomSurveyGroup;
  question_57: CustomSurveyGroup;
  question_58: CustomSurveyGroup;
  question_59: CustomSurveyGroup;
  question_60: CustomSurveyGroup;
  question_61: CustomSurveyGroup;
  question_62: CustomSurveyGroup;
  question_63: CustomSurveyGroup;
  question_64: CustomSurveyGroup;
  question_65: CustomSurveyGroup;
  question_66: CustomSurveyGroup;
  question_67: CustomSurveyGroup;
  question_68: CustomSurveyGroup;
  question_69: CustomSurveyGroup;
  '#webform': Webform;
  '#webform_id': string;
  '#webform_key': string;
  '#webform_parent_key': string;
  '#webform_parent_flexbox': boolean;
  '#webform_depth': number;
  '#webform_children': WebformChildren;
  '#webform_multiple': boolean;
  '#webform_composite': boolean;
  '#webform_parents': string[];
  '#title': null;
  '#admin_title': null;
  '#webform_plugin_id': Type;
}
